// color variants
@import "themes-vars.module.scss";
@import "utils";

// third-party
@import "~react-perfect-scrollbar/dist/css/styles.css";
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }

  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.MuiOutlinedInput-root {
  padding-left: 0px;
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;

    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }

  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }

  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(-10px);
  }

  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }
}

//Style added for offsetting effect of browser scroll bar on UI

:root {
  // overflow-y: scroll;
  overflow-y: hidden;
  overflow-x: hidden;
}

:root body {
  position: absolute;
  width: 100vw;
  overflow: hidden;
  margin: 0;
}

//custom scrollbar
::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #c4c4c4;
}

//Styles for tooltip in the Password reset screen

.tooltip-check-svg {
  color: white;
  border-radius: 50%;
  background-color: green;
  margin-right: 10px;
  // font-size: 12px;
}

.tooltip-check-svg svg {
  font-size: 16px;
  padding-top: 4px;
}

.tooltip-clear-svg {
  color: white;
  border-radius: 50%;
  background-color: red;
  margin-right: 10px;
  // font-size: 12px;
}

.tooltip-clear-svg svg {
  font-size: 16px;
  padding-top: 4px;
}

.Organization-form {
  width: 60%;
}

.customQuestionnaire {
  width: 100%;
}

.Green_circle {
  background: rgba(108, 181, 151, 0.29);
  width: 43.94px;
  height: 43.94px;
  border-radius: 50%;
}

.blue_circle {
  background: #dce4ff;
  width: 43.94px;
  height: 43.94px;
  border-radius: 50%;
}

.red_circle {
  background: #fab2b2;
  width: 43.94px;
  height: 43.94px;
  border-radius: 50%;
}

.yellow_circle {
  background: #ffedc2;
  width: 43.94px;
  height: 43.94px;
  border-radius: 50%;
}

.grey_circle {
  background: #c4c4c4;
  width: 43.94px;
  height: 43.94px;
  border-radius: 50%;
}

.zeroLeftPadding {
  padding-left: 0.4rem !important;
}

.inputGenericField input {
  border: 1px solid #527afb;
  border-radius: 12px;
}

.MuiAutocomplete-popper {
  min-width: 200px !important;
}

.round_phone > div > fieldset {
  border-radius: 12px !important;
}

//to remove arrows from number textfield
/*firefox*/
input[type="number"] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


//candidate & HR model : accordion header - changed to a fixed height
//in HR , the accordions are the 'packages'
.MuiAccordionSummary-root,
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  cursor: pointer;
  // height: 64px !important;
  // min-height: 85px !important;
  min-height: 50px !important;
}

@media (max-width: 1025px) {
  .MuiAccordionSummary-root,
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    height: auto !important;
  }
}

//candidate model : accordion header - removing border radius
.css-p3sd3j .MuiAccordionSummary-root,
.css-1iro1rt .MuiAccordionSummary-root,
.css-18udzqp .MuiAccordionSummary-root,
.css-avtl6f .MuiAccordionSummary-root,
.css-199w97v .MuiAccordionSummary-root {
  border-radius: 0px !important;
}

//HR model : /hr/candidates/create accordions height
// .css-1pvvkxv-MuiButtonBase-root-MuiAccordionSummary-root,.css-1betqn-MuiAccordionSummary-content{
//   height:128px !important;
// }

//navbar/header
.css-kvip5o-MuiToolbar-root {
  padding-bottom: 8px !important;
}

//main content height
.css-1ygzpnb-MuiPaper-root-MuiCard-root {
  min-height: 90vh;
}

@media (max-width: 900px) {
  .css-1ygzpnb-MuiPaper-root-MuiCard-root {
    min-height: 100%;
  }
}

//profile page - read & sign consent form heading texts
#consentTextRef h1 {
  line-height: 30px;
}

@media (max-width: 430px) {
  #consentTextRef h1,
  #read-and-sign-text {
    font-size: 18px !important;
    line-height: 24px !important;
  }
}

@media (max-width: 375px) {
  #read-and-sign-text {
    font-size: 14px !important;
  }

  #consentTextRef p {
    margin-bottom: -25px;
  }
}

//right main section for 1024 screen
@media (max-width: 1025px) {
  .css-o8o7o8-MuiPaper-root,
  .css-8lpodd-MuiPaper-root,
  .css-iwurvx {
    margin-left: 45px;
  }
}

@media (max-width: 769px) {
  .css-o8o7o8-MuiPaper-root,
  .css-8lpodd-MuiPaper-root,
  .css-iwurvx {
    margin-left: 0px;
  }

  //alias or nickname textare box
  .css-pfsodi {
    padding: 16px;
  }
}

//family page - did your mother text
@media (max-width: 330px) {
  #family-did-your-mother-text {
    font-size: 14px;
  }
}

//review page - accordion tables
@media (max-width: 330px) {
  .css-15rigdj {
    overflow-x: auto;
  }
}

//blue button at the bottom
@media (max-width: 330px) {
  .css-1g5eb9q-MuiButtonBase-root-MuiButton-root {
    width: 100%;
  }
}

//review page - declaration check box
@media (max-width: 330px) {
  .css-d7eibo-MuiTypography-root,
  .css-pb4v49-MuiFormControlLabel-root .MuiFormControlLabel-label {
    text-align: center;
  }
}

//hr module candidates,hr packages table
@media (max-width: 380px) {
  //hr candidates table
  .hr_module_table .css-lx641u-MuiDataGrid-root {
    width: 95vw;
    margin-left: -25px;
  }
  //hr packages table
  .css-lx641u-MuiDataGrid-root {
    width: 85vw;
    margin-left: -10px;
  }

  .MuiDataGrid-toolbarContainer .css-ypofq1-MuiDataGrid-toolbarContainer {
    display: flex !important;
  }

  .MuiDataGrid-panelContent .css-1t5wrdm-MuiDataGrid-filterForm {
    width: 300px !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .MuiDataGrid-panelContent
    .css-1t5wrdm-MuiDataGrid-filterForm
    .css-q68e8e-MuiFormControl-root-MuiDataGrid-filterFormColumnInput,
  .MuiDataGrid-panelContent
    .css-1t5wrdm-MuiDataGrid-filterForm
    .css-17vwkjt-MuiFormControl-root-MuiDataGrid-filterFormOperatorInput,
  .MuiDataGrid-panelContent
    .css-1t5wrdm-MuiDataGrid-filterForm
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
    margin-top: 10px !important;
  }

  .MuiDataGrid-panelContent
    .css-1t5wrdm-MuiDataGrid-filterForm
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 285px !important;
  }
}

@media (max-width: 330px) {
  .css-ypofq1-MuiDataGrid-toolbarContainer {
    flex-wrap: wrap !important;
  }
}

//candidate create - select package section

//submit btn width for mobile devices
@media (max-width: 430px) {
  .css-wtnyvs-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
    width: 100% !important;
  }
}

///hr/questionnaire/create?screen=new -> type of question -> single select answer
@media (max-width: 769px) {
  .css-16ikor2-MuiGrid-root {
    width: 90% !important;
  }
}

///hr/settings/manage-notifications -> accordion width for mobile devices
@media (max-width: 330px) {
  .css-30j58c-MuiPaper-root-MuiAccordion-root {
    width: 230px !important;
  }

  .css-1py3bo1-MuiTypography-root {
    width: 90% !important;
    word-break: break-word !important;
  }
}

//hr/candidates/create -> text field in jurisdiction
@media (max-width: 430px) {
  .css-115e336-MuiAutocomplete-root,
  .css-cbm7bp-MuiAutocomplete-root
    .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    width: 230px !important;
  }
}

//making accordion switch's color consistent for UAT deployed app
//when closed
.css-aa4224 .MuiSwitch-track {
  border-radius: 13px;
  background-color: #e9e9ea !important;
  opacity: 1;
}

//when expanded or opened
.css-cfci01.Mui-checked + .MuiSwitch-track,
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: rgb(82, 122, 251) !important;
}
